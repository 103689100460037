import React, { useEffect } from "react"
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import { navigate, useIntl } from "gatsby-plugin-intl"
import SeniorJavaDeveloper from '../../../components/VacancyPage/SeniorJavaDeveloperPage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'
import SEO from '../../../components/seo'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (global.window && locale === 'de') {
      navigate('/career/')
    }
  }, [])

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSeniorJavaDeveloper)}
        description={formatMessage(metaDescription.seniorJavaDeveloperDescription)}
        lang={locale}
      />
      <SeniorJavaDeveloper />
    </Layout>
  )
}
export default IndexPage
