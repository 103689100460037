import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Senior Java Developer',
  introTitle: 'Senior Java Developer (m/f/d)',
  introInfo: '<p>freshcells systems engineering GmbH is a software service provider based in Düsseldorf with a dynamic team of over 40 creative experts from various fields.</p><p>We focus on authenticity, individual talent development and open communication. We build on respect, trust and responsibility. As an innovation-driven company, we invest in new ideas. We know that the best results are achieved as a team. We make mistakes, but we learn from them. We are open and courageous for new paths.</p><p>Join us on our exciting journey in what is probably the most important and forward-looking area - software development - as a Senior Java Developer (m/f/d) - Software Development!</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'Design and improve our high-performance Java web applications with industry-standard frameworks',
  responsibility2: 'Conceptual design and implementation of new features for sophisticated software solutions in cooperation with the project management, design, and development teams',
  responsibility3: 'Writing automated software tests and setup deployments for web applications',
  responsibility4: 'Profiling and discovering performance bottlenecks in our Java applications',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'Several years of hands-on experience in Software Engineering',
  qualification2: 'Solid knowledge of Java (8, 11) and object-oriented software architectures',
  qualification3: 'Experience with the Spring (Boot) framework',
  qualification4: 'Experience in SQL',
  qualification5: 'Experience in Software Integration Development (SOAP/REST)',
  qualification6: 'You are comfortable with microservices architectures, API-based and event-based communication',
  qualification7: 'Using build tools (like maven, gradle)',
  qualification8: 'Experience in agile development in a team and an organized and result-oriented work ethic for sustainable and scalable web systems',
  qualification9: 'Good knowledge of how to design distributed systems',
  qualification10: 'Good knowledge of the English language, regardless of your mother tongue',
  nicetohaveTitle: 'Nice to have',
  nicetohave1: 'Experience in NoSQL',
  nicetohave2: 'Previous experience with lucene/solr/elasticsearch',
  nicetohave3: 'Experience with Docker and ideally, experience Kubernetes and Helm',
  nicetohave4: 'Experience with Apache Ignite and Redis',
  nicetohave5: 'Continuous Integration and Deployment with Jenkins (or others)',
  nicetohave6: 'Experience with GraphQL is a plus',
  nicetohave7: 'Experience with Reactive programming (Spring WebFlux)'
})
